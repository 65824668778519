@import "../../_globalColor";

.media-container {
  padding: 4rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.slider-wrapper {
  .slick-prev:before,
  .slick-next:before {
    color: $textColor;
  }

  .slick-dots li button:before {
    color: $textColor;
  }

  .slick-slide > div {
    margin: 0 10px;
  }

  .slick-list {
    margin: 0 -10px;
  }
}

@media (max-width: 768px) {
  .media-container {
    padding: 2rem 1rem;
  }
  
  .section-title {
    font-size: 2rem;
  }
}