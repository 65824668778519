@import "../../_globalColor";

.education-heading {
  font-size: 56px;
  font-weight: 400;
}

.education-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 0px;
  margin: 0px auto;
  margin-top: 2rem;
}

.education-section > * {
  flex: 1;
  margin-bottom: 30px;
}

.education-card-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

@media (max-width: 1380px) {
  .education-card-container {
    padding-top: 0px;
  }
  .education-heading {
    font-size: 40px;
  }
  .education-text-subtitle {
    font-size: 18px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .education-card-container {
    padding-top: 0px;
  }
  .education-heading {
    font-size: 30px;
    text-align: center;
  }
  .education-text-subtitle {
    font-size: 16px;
  }
}
.publication-card-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.publication-card-footer span.publication-tag {
  background: $buttonColor;
  color: $lightBackground3;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in;
}
