@import "../../_globalColor";

.contact-div-main {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
}

.dark-mode a {
  color: $textColorDark;
}

.contact-div-main > * {
  flex: 1;
  max-width: 800px;
}

.contact-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-title {
  font-size: 65px;
  font-weight: 400;
  line-height: 1;
  word-wrap: break-word;
  text-align: center;
  margin-bottom: 1rem;
}

.contact-detail,
.contact-detail-email {
  margin-top: 20px;
  font-size: 40px;
  font-weight: 400;
  color: $subTitle;
  text-decoration: none;
  display: inline-block;
}

.contact-detail:hover,
.contact-detail-email:hover {
  color: $textColor;
  text-shadow: 2px 1px 2px $contactDetailHoverTS;
  transition: all 0.3s;
}

.contact-subtitle {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.contact-text-div {
  margin-top: 1.5rem;
  text-align: center;
}

.contact-margin-top {
  margin-top: 4rem;
}

.contact-image-div > img {
  max-width: 100%;
  height: auto;
  margin-left: 1.5rem;
  margin-top: -4rem;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-title {
    font-size: 40px;
  }

  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
  }

  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }

  .contact-image-div {
    display: none;
  }
}
