@import "../../_globalColor";

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 4rem 1rem;
}

.section-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.faq-items {
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .faq-container {
    padding: 2rem 1rem;
  }
  
  .section-title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}